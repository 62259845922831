import React from "react";
import { Card, Col, Container, Row, Stack } from "react-bootstrap";
import TablePanel from "./TablePanel";
import PlotPanel from "./PlotPanel";
import { MenuPanel } from ".";

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataPoints: [
                { "day": 0, "ctScanLevels": 0, "auc": null },
                { "day": 90, "ctScanLevels": null, "auc": null },
                { "day": 365, "ctScanLevels": null, "auc": null }
            ], // data points
            aucAt90: undefined,
            aucAt365: undefined,
            result: undefined
        };
    }
    
    /**
     * Add the passed point to the state
     */
    addPoint = (newPoint) => {
        let newDataPoints = [...this.state.dataPoints];
        newDataPoints.push(newPoint);
        this.setPoints(newDataPoints);
    }

    addEmptyPointAtIndex = (index) => {
        let newDataPoints = [...this.state.dataPoints];
        newDataPoints.splice(index, 0, {day: null, ctScanLevels: null, auc: null});
        this.setPoints(newDataPoints);
    }

    /**
     * Updates the point in the state at the passed index.
     */
    setPoint = (index, point) => {
        let newDataPoints = [...this.state.dataPoints];
        newDataPoints[index] = point;
        this.setPoints(newDataPoints);
    }

    /**
     * Replaces all the points in the state with the passed new points.
     * @param {*} newDataPoints 
     */
    setPoints = (newDataPoints) => {
        this.setState({
            dataPoints: newDataPoints,
            aucAt90: undefined,
            aucAt365: undefined,
            result: undefined
        });
    }

    // Set points and results
    updateResults = (newDataPoints) => {

        const pointsBefore90 = newDataPoints.filter(point => point.day <= 90).map(point => point.auc);
        const sumAt90 = pointsBefore90.reduce((a, b) => a + b, 0);

        let aucAt365;
        let resultAt365;

        if (newDataPoints.find(point => point.day >= 90)) {
            let sum = newDataPoints.filter(point => point.day <= 365).map(point => point.auc);
            aucAt365 = toFixed(sum, 2);
            resultAt365 = classifyAt365(aucAt365);
        } else {
            aucAt365 = undefined;
            resultAt365 = undefined;
        }

        this.setState({
            dataPoints: newDataPoints,
            aucAt90: toFixed(sumAt90, 2),
            aucAt365,
            resultAt90: classifyAt90(sumAt90),
            resultAt365
        });
    }

    /**
     * Remove the point in the state at the passed index.
     * @param {*} index 
     */
    removePoint = (index) => {
        let newDataPoints = [...this.state.dataPoints];
        newDataPoints.splice(index, 1);
        this.setPoints(newDataPoints);
    }

    /**
     * Removes all the points in the state.
     */
    removeAllPoints = () => { this.setPoints([])}

    render() {

        // Check that every point from 90 to 365 has conc.
        let pointsFrom90AreValid = true;
        for (let point of this.state.dataPoints) {
            if (point.day <= 90)
                continue;
            if (point.ctScanLevels === null) {
                pointsFrom90AreValid = false;
                break;
            }
        }

        // If no, filter out every point from 90
        let pointsToPlot = pointsFrom90AreValid ? this.state.dataPoints : this.state.dataPoints.filter(p => p.day <= 90);

        let reportData = {
            dataPoints: this.state.dataPoints,
            aucAt90: this.state.aucAt90,
            aucAt365: this.state.aucAt365,
            resultAt90: this.state.resultAt90,
            resultAt365: this.state.resultAt365
        };

        return (
            <Container fluid className="p-3">
                <Row>
                    <MenuPanel reportData={reportData} setPoints={this.setPoints} />
                </Row>
                <Row>
                    <Col className="xs-12 md-5 mx-4">
                        <TablePanel data={this.state.dataPoints}
                            addPoint={this.addPoint}
                            addEmptyPointAt={this.addEmptyPointAtIndex}
                            setPoint={this.setPoint}
                            setPoints={this.setPoints}
                            removePoint={this.removePoint}
                            removeAllPoints={this.removeAllPoints}
                            updateResults={this.updateResults} />
                    </Col>
                    <Col className="xs-12">
                        <Stack gap={2}>
                            <PlotPanel data={pointsToPlot} />
                            <Row>
                                <Col xs={6}>
                                    <AucPanel title="90-days result" auc={this.state.aucAt90} />
                                </Col>
                                <Col xs={6}>
                                    <AucPanel title="365-days result" auc={this.state.aucAt365} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <ResultPanel title="90-days classification" result={this.state.resultAt90} />
                                </Col>
                                <Col xs={6}>
                                    <ResultPanel title="365-days classification" result={this.state.resultAt365} />
                                </Col>
                            </Row>
                            <Row>
                                <small>
                                    *Tacrolimus dosing and target trough concentrations may be adjusted at the
                                    discretion of the responsible clinician taking into account the individual
                                    risk of rejection, signs of infection and renal function. The CET tool is
                                    aimed to aid in this process but it should not replace the clinical
                                    judgement. 
                                </small>
                            </Row>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        );
    }
}

/**
 * Enum for result classifications.
 * @readonly
 * @enum {{ name: string, hex: string }}
 */
 const Results = Object.freeze({
    AGGRESIVE_MINIMIZATION: { name: "Aggressive minimization",  hex: "#A020F0" },
    MINIMIZATION: { name: "Minimization", hex: "#008000" },
    CONVENTIONAL_EXPOSURE: { name: "Conventional exposure", hex: "#ffff00" },
    HIGH_EXPOSURE: { name: "High exposure", hex: "#ff0000" }
 });

const classifyAt90 = (aucAt90) => {
    
    let classificationAt90;
    if (!aucAt90) {
        classificationAt90 = undefined;
    } else if (aucAt90 < 320) {
        classificationAt90 = Results.AGGRESIVE_MINIMIZATION;
    } else if (321 < aucAt90 && aucAt90 < 579) {
        classificationAt90 = Results.MINIMIZATION;
    } else if (580 < aucAt90 && aucAt90 < 839) {
        classificationAt90 = Results.CONVENTIONAL_EXPOSURE;
    } else if (aucAt90 > 840) {
        classificationAt90 = Results.HIGH_EXPOSURE;
    }

    return classificationAt90;
}

const classifyAt365 = (aucAt365) => {

    let classificationAt365;
    if (!aucAt365) {
        classificationAt365 = undefined;
    } else if (aucAt365 < 1150) {
        classificationAt365 = Results.AGGRESIVE_MINIMIZATION;
    } else if (1151 < aucAt365 && aucAt365 < 2219) {
        classificationAt365 = Results.MINIMIZATION;
    } else if (2220 < aucAt365 && aucAt365 < 3049) {
        classificationAt365 = Results.CONVENTIONAL_EXPOSURE;
    } else if (aucAt365 > 3050) {
        classificationAt365 = Results.HIGH_EXPOSURE;
    }

    return classificationAt365;
}

/** Utility function to truncate decimals of a number. */
function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
}

const AucPanel = (props) => {
    return (
        <Card border="dark">
            <Card.Header as="h5">{props.title}</Card.Header>
            <Card.Body>
                <Card.Title>{props.auc ? props.auc : ''}</Card.Title>
            </Card.Body>
        </Card>
    )
}

const ResultPanel = (props) => {

    return (
        <Card id="resultPanel" border="dark" style={{"backgroundColor": props.result ? props.result.hex : "#FFFFFF"}}>
            <Card.Header as="h5">{props.title}</Card.Header>
            <Card.Body>
                <Card.Title>{props.result ? props.result.name : ''}</Card.Title>
            </Card.Body>
        </Card>
    );
}

export default Home;
