import React from "react";

import Plot from "react-plotly.js";

function PlotPanel(props) {

    let days = []
    let ctScanLevels = [];

    if (props.data) {
        days = props.data.map(dataPoint => dataPoint["day"]);
        ctScanLevels = props.data.map(dataPoint => dataPoint["ctScanLevels"]);
    }

    return <Plot
      data={[
        {
          x: days,
          y: ctScanLevels,
          type: 'scatter',
          mode: 'lines+markers',
          name: 'CT scan levels',
          marker: {color: 'red'},
        }
      ]}
      layout={ {
          xaxis: {
            title: {
                text: 'Days after Transplantation'
            }    
          },
          yaxis: {
              title: {
                  text: 'Tacrolimus through concentrations (ng/mL)'
              }
          },
          autosize: true
        }}
      config={ {
        displaylogo: false,
      }}
    />;
}

export default PlotPanel;
