import { Card, Container, ListGroup } from "react-bootstrap";

function HelpPanel() {
    return (
      <Container fluid className="p-3">
          <Card>
              <Card.Body>
                <Card.Title>Help</Card.Title>
                <ListGroup as="ol" numbered variant="flush">
                    <ListGroup.Item as="li">To calculate “Cumulative exposure to tacrolimus” (CET), all determinations of trough concentrations need to be plotted, together with their correspondent post-operative day.</ListGroup.Item>
                    <ListGroup.Item as="li">CET may be calculated either at 90 days, and/or at 365 days after transplantation.</ListGroup.Item>
                    <ListGroup.Item as="li">Tacrolimus trough concentrations may be introduced directly on the webpage or pre-filled in the downloadable excel template, which may be edited at your discretion. Please, ensure that the initial determination is 0 ng/mL at post-operative day 0, and the final determination contains data at post-operative day 90 or post-operative day 365.</ListGroup.Item>
                    <ListGroup.Item as="li">The option “import file” can be used to upload the excel template containing your patient’s data of tacrolimus trough concentrations. After using this option, click on “calculate” to get CET values.</ListGroup.Item>
                    <ListGroup.Item as="li">After filling your patient’s data, you can generate a report file in pdf format for your record by clicking on the “Download report” icon.</ListGroup.Item>
                    <ListGroup.Item as="li">If you experience difficulties using our webpage, feel free to contact at: <a href="mailto:h02ropem@uco.es">h02ropem@uco.es</a></ListGroup.Item>
                </ListGroup>
              </Card.Body>
          </Card>
      </Container>
    );
  }

export default HelpPanel;
