import React from "react";
import { Col, Container, Image, Row, Stack } from "react-bootstrap";

function Acknowledgments() {
  return (
    <Container fluid className="p-3">
      <Stack gap="3">
        <h1 className="display-3">Acknowledgments</h1>
        <SmallPartners />
        <BigPartners />
      </Stack>
    </Container>
  );
}

function SmallPartners() {

  let basePath = process.env.REACT_APP_CONTEXT;
  let firstRow = [
    `${basePath}/images/small_partners/lafe.png`,
    `${basePath}/images/small_partners/clinicbcn.png`,
    `${basePath}/images/small_partners/lozano_blesa.png`,
    `${basePath}/images/small_partners/gurutzeta_ospitalea.png`,
    `${basePath}/images/small_partners/vall_dhebron.png`,
    `${basePath}/images/small_partners/rio_hortega.png`,
    `${basePath}/images/small_partners/valldecilla.png`
  ];

  let secondRow = [
    `${basePath}/images/small_partners/hurs.png`,
    `${basePath}/images/small_partners/virgen_del_rocio.png`,
    `${basePath}/images/small_partners/gregorio_maranon.png`,
    `${basePath}/images/small_partners/carlos_haya.png`,
    `${basePath}/images/small_partners/virgen_de_las_nieves.png`,
    `${basePath}/images/small_partners/alacant.png`,
    `${basePath}/images/small_partners/candelaria.png`,
    `${basePath}/images/small_partners/arrixaca.png`
  ]

  return (
    <>
      <Row>
        {firstRow.map((path, index) => <Col key={index}><Image src={path} fluid /></Col>)}
      </Row>
      <Row>
        {secondRow.map((path, index) => <Col key={index}><Image src={path} fluid /></Col>)}
      </Row>
    </>
  );
}

function BigPartners() {

  let basePath = process.env.REACT_APP_CONTEXT;
  let row = [
    `${basePath}/images/big_partners/ciberehd.png`,
    `${basePath}/images/big_partners/seth.png`,
    `${basePath}/images/big_partners/imibic.png`
  ];

  return (
    <>
      <Row>
        { row.map((path, index) => <Col key={index}><Image src={path} fluid /></Col>) }
      </Row>
    </>
  );
}

export default Acknowledgments;
