import React from "react";

import { Button, Form, Stack } from "react-bootstrap";


const MenuPanel = (props) => {

    return (
        <Stack direction="horizontal" gap={2}>
            <DownloadTemplateButton />
            <ImportButton setPoints={props.setPoints}/>
            <ExportButton dataPoints={props.reportData.dataPoints} />
            <ReportButton reportData={props.reportData} />
        </Stack>
    )
}

const ImportButton = (props) => {
    
    /** Triggered when the import button is clicked. It fires the file input dialog. */
    const handleClick = e => {
        let importFileInput = document.getElementById("importFileInput");
        importFileInput.click();
    }

    /**
     * When the input file gets a different CSV file, this is uploaded to the backend.
     */
    const handleChange = e => {
        let files = e.target.files;
        if (files.length === 1) {
            makeRequest(files[0]);
        }
    }

    /**
     * Make request to the backend to read the CSV and get the points back. This points are 
     * later passed back to the table.
     * @param {*} file File selected with *importFileInput*.
     */
    const makeRequest = file => {
        let data = new FormData();
        data.append('file', file);

        let url = `${process.env.REACT_APP_CONTEXT}/api/import`;
        fetch(url, { method: 'POST', body: data })
            .then(response => response.json())
            .then(data => {
                props.setPoints(data); // Callback to set imported points
                // Clear importFileInput value (value = "")
                document.getElementById("importFileInput").value = "";
            })
    }

    return (
        <>
            <Form.Control id="importFileInput" type="file" className="d-none" onChange={handleChange} />
            <Button variant="secondary" onClick={handleClick}>Import file</Button>
        </>
    )
}

const DownloadTemplateButton = () => {

    const handleClick = e => {
        fetch(`${process.env.REACT_APP_CONTEXT}/api/download-template`, {
            method: 'GET'
        })
        .then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            downloadFile(url, "template.csv");
        });
    }

    return (
        <Button className="ms-auto" variant="secondary" onClick={handleClick}
            download="template.csv">Download template</Button>
    )
}

const ExportButton = (props) => {

    /** Data points in the table are sent to the backend that generates and return a CSV file. */
    const handleClick = e => {
        if (props.dataPoints && props.dataPoints.length > 0) {
            let jsonBody = JSON.stringify(props.dataPoints);

            fetch(`${process.env.REACT_APP_CONTEXT}/api/export`, {
                method: 'POST',
                body: jsonBody,
                headers: new Headers({'Content-Type': 'application/json'})
            })
            .then(response => response.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                downloadFile(url, "data.csv");
            });
        }
    }

    return (
        <Button variant="secondary" onClick={handleClick}>Export file</Button>
    )
}

const ReportButton = (props) => {

    /** Data points in the table are sent to the backend that generates and return a report. */
    const handleClick = e => {
        if (props.reportData) {
            let jsonBody = JSON.stringify(props.reportData);
            console.log(jsonBody);

            fetch(`${process.env.REACT_APP_CONTEXT}/api/report`, {
                method: 'POST',
                body: jsonBody,
                headers: new Headers({'Content-Type': 'application/json'})
            })
            .then(response => response.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                downloadFile(url, "report.pdf");
            });
        }
    }

    return (
        <Button variant="secondary" onClick={handleClick}>Download report</Button>
    )
}

const downloadFile = (url, fileName) => {
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    // we need to append the element to the dom -> otherwise it will not work in firefox
    document.body.appendChild(a);
    a.click();
    a.remove(); // afterwards we remove the element again
}

export default MenuPanel;
