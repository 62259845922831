import React from "react";
import { useState } from "react";

import { Button, OverlayTrigger, Stack, Table, Toast, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";


/** Utility function to truncate decimals of a number. */
function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
}

const TacrolimusTable = (props) => {

    /** Error string. */
    const [error, setError] = useState();
    
    /** Warning string. */
    const [warning, setWarning] = useState();

    const updateConcentration = (event, index) => {
        let parsedValue = parseFloat(event.target.value);
        let newPoint = props.data[index];
        newPoint["ctScanLevels"] = Number.isNaN(parsedValue) ? null : parsedValue;
        props.setPoint(index, newPoint);
    }

    const updateDay = (event, index) => {
        let parsedValue = parseInt(event.target.value);
        let newPoint = props.data[index];
        if (Number.isNaN(parsedValue) || [0, 90, 365].includes(parsedValue)) {
            newPoint.day = null;
        } else {
            newPoint.day = parsedValue;
        }
        props.setPoint(index, newPoint);
    }

    const clearError = () => setError(undefined);
    const clearWarning = () => setWarning(undefined);

    const validate = () => {

        // Validate first for each row: missing days and concentrations
        for (let rowIndex=0; rowIndex < props.data.length; rowIndex++) {
            let currentPoint = props.data[rowIndex];
            if (currentPoint["day"] === null) {
                setError(`Missing day at row ${rowIndex}`);
                return false;
            }

            // Skip row for day 0.
            if (currentPoint["day"] === 0) {
                continue;
            }

            if (currentPoint["ctScanLevels"] === null) {
                let message = `Missing concentration for day ${currentPoint["day"]}`
                if (currentPoint["day"] <= 90) {
                    setError(message);
                    return false;
                }
                if (currentPoint["day"] > 90 && currentPoint["day"] <= 365) {
                    setWarning(message);
                }
            }
        }

        // Validate days in order
        for (let i = 0; i < props.data.length - 1; i++) {
            if (props.data[i].day > props.data[i+1].day) {
                setError("Days in table are not ordered");
                return false;
            }
        }

        // TODO: validate days: not duplicated 

        return true;
    }

    const calculateAuc = () => {
        if (!validate()) return;

        if (props.data.length > 1) {

            let newData = props.data;

            // Clear auc for every point
            for (const point of newData) {
                point.auc = null;
            }

            for (let i = 1; i < props.data.length; i++) {
                let previousPoint = props.data[i-1];
                let currentPoint = props.data[i];

                if (currentPoint.ctScanLevels === null)
                    break;

                let auc = (previousPoint.ctScanLevels + currentPoint.ctScanLevels)/2 * (currentPoint.day - previousPoint.day);
                newData[i].auc = toFixed(auc, 2);
            }

            props.updateResults(newData);
        }
    };

    return (
        <Stack direction="vertical" gap={2}>
            <Table responsive>
                <thead>
                    <tr>
                        <td>Post-operative day</td>
                        <td>Through concentrations</td>
                        <td>AUC</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody id="tableBody">
                    {props.data.map((point, index) =>
                        <TacrolimusTableRow key={index} point={point} index={index}
                            updateDay={updateDay} updateConcentration={updateConcentration}
                            addEmptyPointAt={props.addEmptyPointAt}
                            removePoint={props.removePoint} />)}
                </tbody>

            </Table>
            <Button variant="primary" onClick={calculateAuc}>Calculate</Button>
            <ErrorToast error={error} clearError={clearError} />
            <WarningToast warning={warning} clearWarning={clearWarning} />
        </Stack>
    );
}

// props
// - point
// - index
// - addEmptyPointAt
function TacrolimusTableRow(props) {

    // day cell
    let dayCell;
    if (![0, 90, 365].includes(props.point["day"])) {
        dayCell = <td><input type="number" min="0" step="0"
            onChange={(event) => props.updateDay(event, props.index)}
            value={props.point.day === null ? '' : props.point.day}/></td>;
    } else {
        dayCell = <td>{props.point["day"]}</td>;
    }

    let concentrationCell;
    if (props.point["day"] !== 0) {
        concentrationCell = <td><input type="number" min="0" step="0.1"
            onChange={(event) => props.updateConcentration(event, props.index)}
            value={props.point.ctScanLevels === null ? '' : props.point.ctScanLevels} /></td>;
    } else {
        concentrationCell = <td>{props.point["ctScanLevels"]}</td>;
    }

    let addButton = <AddButton key={'addButton' + props.index} index={props.index} addEmtpyPointAt={props.addEmptyPointAt} />;
    let removeButton = <RemoveButton key={'removeButton' + props.index} index={props.index} removePoint={props.removePoint} />;

    let buttons;
    if (props.point["day"] === 0) {
        buttons = [addButton];
    } else if (props.point["day"] === 90) {
        buttons = [addButton];
    } else if (props.point["day"] === 365) {
        buttons = [];
    } else if (props.point["day"] < 365) {
        buttons = [addButton, removeButton];
    } else {
        buttons = [addButton, removeButton];
    }

    return (<tr>
        {dayCell}
        {concentrationCell} 
        <td>{props.point["auc"]}</td>
        <td><Stack direction="horizontal" gap={1}>{buttons}</Stack></td>
        </tr>);
}

function ErrorToast(props) {

    return (
        <Toast bg="danger" onClose={props.clearError} show={props.error !== undefined}
            delay={3000} autohide>
            <Toast.Header>
                <strong className="me-auto">Error in data</strong>
            </Toast.Header>
            <Toast.Body>{props.error}</Toast.Body>
        </Toast>
    );
}

function WarningToast(props) {

    return (
        <Toast bg="warning" onClose={props.clearWarning} show={props.warning !== undefined}
            delay={3000} autohide>
            <Toast.Header>
                <strong className="me-auto">Issue in data after day 90</strong>
            </Toast.Header>
            <Toast.Body>{props.warning}</Toast.Body>
        </Toast>
    );
}


/** Add button used in the Tacrolimus table. */
const AddButton = (props) => {

    const handleClick = (index) => props.addEmtpyPointAt(index+1);

    return (
        <OverlayTrigger placement="right" overlay={<Tooltip>Add a point</Tooltip>}>
            <Button className="float-end mx-1" variant="primary" size="sm"
                onClick={() => handleClick(props.index)}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </OverlayTrigger>
    )
}

/** Remove button used in the TracrolimusTable. */
const RemoveButton = (props) => {

    const handleClick = (index) => props.removePoint(index);

    return (
        <OverlayTrigger placement="right" overlay={<Tooltip>Remove point</Tooltip>}>
            <Button className="mx-1" variant="warning" size="sm"
                onClick={() => handleClick(props.index)}>
                <FontAwesomeIcon icon={faMinus} />
            </Button>
        </OverlayTrigger>
    );
}

export default TacrolimusTable;
