import { React } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function Navigation() {

  return (
    <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" sticky="top">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">Cetweb</Navbar.Brand>
        <Navbar.Toggle aria-controls="nav-collapse" />
        <Navbar.Collapse id="nav-collapse">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" href="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about" href="/about">About</Nav.Link>
            <Nav.Link as={Link} to="/acknowledgments" href="/acknowledgments">Acknowledgments</Nav.Link>
            <Nav.Link as={Link} to="/help" href="/help">Help</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
