import React from "react";
import { Card, Col, Container, Image, Row, Stack, Table } from "react-bootstrap";

function About() {
  return (
    <Container fluid className="p-3">
      <Stack gap={3}>
        <h1 className="display-3">About cumulative exposure to Tacrolimus</h1>
        <MethodologyPanel />

        <Row>
          <Col><EvidencePanel /></Col>
          <Col><EquivalencePanel /></Col>
        </Row>

        <ReferencesPanel />

      </Stack>
    </Container>
  );
}

function MethodologyPanel() {

  let basePath = process.env.REACT_APP_CONTEXT;
  let src0 = `${basePath}/images/graphs/graph0.png`;
  let src1 = `${basePath}/images/graphs/graph1.png`;
  let src2 = `${basePath}/images/graphs/graph2.png`;
  let src3 = `${basePath}/images/graphs/graph3.png`;


  return (
    <Card>
      <Card.Header>
        Calculation methodology
      </Card.Header>
      <Card.Body>
        Cumulative exposure to tacrolimus (CET) is calculated as the area under curve
        of trough concentrations over time. All measurements of trough concentrations
        of tacrolimus for a certain patient are plotted in a time-dependent graph in
        which trough levels of tacrolimus are represented in the “Y” axis while time
        after transplantation (in days) is represented in the “X” axis. Then, each
        tacrolimus trough level determination is joined with the next delineating a
        curve. Finally, the area under curve of trough concentrations is calculated
        by the Wagner-Nelson equation, which is the sum of the area of the first
        triangle and subsequent trapezes.

        <Row>
          <Col sm={8} md={2}><Image src={src0} /></Col>
          <Col sm={8} md={2}><Image src={src1} /></Col>
          <Col sm={8} md={2}><Image src={src2} /></Col>
          <Col sm={8} md={2}><Image src={src3} /></Col>
        </Row>
        <h1 className="display-6">CET= A1+A2+A3…+A11</h1>
      </Card.Body>
    </Card>
  );
}

function EvidencePanel() {
  return (
    <Card>
      <Card.Header>Supporting evidence</Card.Header>
      <Card.Body>
        Cumulative exposure to tacrolimus has been associated with renal impairment (1)
        and malignancy (2) after liver transplantation. In a retrospective study
        including 455 liver transplant patients from the Royal Free Hospital (London,
        UK) and from the Hospital Universitario Reina Sofía (Córdoba, Spain),
        conventional or high exposure to tacrolimus according to CET resulted in a more
        pronounced eGFR decline within the first 3 months as compared with minimization
        (23.3 ml/min vs 9.5 ml/min; p&lt;0.001) (1). In another multicenter case-control
        nested study including 2,495 patients, CET was an independent predictor of any
        type of malignancy, de novo malignancy and internal neoplasms. The most
        pronounced pro-oncogenic effect was demonstrated in patients with high exposure
        to tacrolimus, and particularly within the first 3 months (2).
      </Card.Body>
    </Card>
  )
}

function EquivalencePanel() {
  return (
    <Card>
      <Card.Header>Equivalence of target through levels and CET</Card.Header>
      <Card.Body>
        <Table size="sm" responsive>
          <thead>
            <tr>
              <th>Category</th>
              <th>Target through concentrations (ng/mL)</th>
              <th>AUCtc 3 months</th>
              <th>AUCtc 6 months</th>
              <th>AUCtc 12 months</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Aggressive minimization</td>
              <td><p>&lt;4 (first month)</p><p>&lt;3 (thereafter)</p></td>
              <td>&lt;320</td>
              <td>&lt;590</td>
              <td>&lt;1,150</td>
            </tr>
            <tr>
              <td>Minimization</td>
              <td><p>4-6 (first month)</p><p>4 (thereafter)</p></td>
              <td>321-579</td>
              <td>591-1,109</td>
              <td>1,151-2,219</td>
            </tr>
            <tr>
              <td>Conventional</td>
              <td><p>7-10 (first month)</p><p>6-8 (thereafter)</p></td>
              <td>580-839</td>
              <td>1,110-1,569</td>
              <td>2,220-3,049</td>
            </tr>
            <tr>
              <td>High exposure</td>
              <td><p>&gt;10 (first month)</p><p>8 (thereafter)</p></td>
              <td>&gt;840</td>
              <td>&gt;1,570</td>
              <td>&gt;3,050</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

function ReferencesPanel() {
  return (
    <Card>
      <Card.Header>References</Card.Header>
      <Card.Body>
        <ol>
          <li>Rodriguez-Peralvarez M, Guerrero M, De Luca L, Gros B, Thorburn D, Patch
            D, et al. Area Under Trough Concentrations of Tacrolimus as a Predictor of
            Progressive Renal Impairment After Liver Transplantation. Transplantation
            2019;103:2539-2548.</li>
          <li>Rodríguez-Perálvarez M, Colmenero J, González A, Gastaca M, Curell A,
            Caballero-Marcos A, et al. Cumulative exposure to tacrolimus and incidence
            of cancer after liver transplantation. Am J Transplant. 2022 Mar 14.
            doi: 10.1111/ajt.17021. Online ahead of print.</li>
        </ol>
      </Card.Body>
    </Card>
  );
}

export default About;